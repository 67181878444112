<template>
  <div>
    <template v-if="slug || url">
      <img 
        :src="slug ? BACKEND_URL+ '/media/' + slug: url" 
        alt=""
        style="
          width:9rem;
          border-radius: 8px;
        "
      >
    </template>
    <template v-else>
      <div
        style="
          width: 9rem;
          height: 80px;
          border-radius: 8px;
          background-color:inherit;
        "
      >

      </div>
    </template>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
export default {
  props:{
    slug: String,
    url: String,
  },
  setup(){
    const BACKEND_URL = ref(process.env.VUE_APP_URL)
    return {
      BACKEND_URL
    }
  }
}
</script>