import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import * as BANNERS from '@/views/components/whitelabel-templates/App/config/PageEdit/BannerManagement/external/banners-new/banners-list/res.json'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {translatableText} from "@core/utils/utils";
import {axiosErrorHandle} from "@core/utils/errorHandler";
import * as FOOTERS
  from "@/views/components/whitelabel-templates/App/config/PageEdit/FooterManagement/external/footers-new/footers-list/res.json";

export default function useBannersList() {
  // Use toast
  const toast = useToast()
  const refBannerListTable = ref(null)
  const loading = ref(false)

  // Table Handlers
  const tableColumns = [
    { key: 'image', sortable: true },
    { key: 'name', sortable: true },
    { key: 'description', sortable: true },
    { key: 'type', sortable: true },
    { key: 'actions' },
  ]
  const perPage = ref(10)
  const totalBanners = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(false)
  const recordDeleted = ref(false)

  const dataMeta = computed(() => {
    const localItemsCount = refBannerListTable.value ? refBannerListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalBanners.value,
    }
  })

  const refetchData = () => {
    refBannerListTable.value.refresh()
  }

  const selectBanner = (id) => {
    let banners = refBannerListTable.value.localItems
    setTimeout(() => {
      banners = banners.map(item => {
        item.selected = item._id === id;
        return item
      })
    }, 3000)

    refBannerListTable.value.localItems = banners
    refBannerListTable.value.refresh()
  }


  watch([currentPage, perPage, searchQuery, recordDeleted], () => {
    refetchData()
  })

  const fetchBanners = (ctx, callback) => {
    loading.value = true

    store.dispatch('app-banner/fetchBanners', {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,

      })
      .then(response => {
        loading.value = false
        // const { banners, total } = response.data
        // callback(banners)
        // totalBanners.value = total

        setTimeout(() => {
          loading.value = false
          // Override data
          const {footers, total} = FOOTERS.data
          callback(footers)
          totalBanners.value = total
        }, 2000)

      })
      .catch(async (error) => {
        loading.value = false
        toast({
          component: ToastificationContent,
          props: {
            title: await translatableText({text: axiosErrorHandle(error)}),
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    selectBanner,
    fetchBanners,
    tableColumns,
    perPage,
    currentPage,
    totalBanners,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refBannerListTable,
    refetchData,
    loading,
    recordDeleted,
  }
}
