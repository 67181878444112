import axios from '@/libs/axios'
const APP_URL = process.env.VUE_APP_URL

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchBanners(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${APP_URL}/banners`, { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchBanner(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${APP_URL}/banners/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addBanner(ctx, bannerData) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${APP_URL}/banners`, bannerData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
   
    updateBanner(ctx, { id, bannerData }) {
      return new Promise((resolve, reject) => {
        axios
          .put(`${APP_URL}/banners/${id}`, bannerData )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    removeBanner(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`${APP_URL}/banners/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
