<template>
  <div>
    <banner-list-add-new
        :is-add-new-banner-sidebar-active.sync="isAddNewBanner"
        @refetch-data="refetchData"
        :pages="pages"
    />
    <banners-edit
        :pages="pages"
        :show="showModal"
        :banner="banner"
        @close="showModal = false"
        @refetch-data="refetchData"
    />
    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>{{ $t("labels.show") }}</label>
            <v-select
                v-model="perPage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
            />
            <label>{{ $t("labels.entries") }}</label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                  v-model="searchQuery"
                  class="d-inline-block mr-1"
                  :placeholder="$t('buttons.search') + '...'"
              />
              <div>
                <b-button variant="primary" @click="isAddNewBanner = true">
                  <span class="text-nowrap">{{ $t("buttons.add") }}</span>
                </b-button>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-overlay :show="loading"
                 :variant="$store.state.appConfig.layout.skin"
                 blur="2"
                 class="p-50">
        <b-table
            ref="refBannerListTable"
            class="position-relative"
            :items="fetchBanners"
            responsive
            :fields="tableColumns"
            primary-key="_id"
            :sort-by.sync="sortBy"
            show-empty
            :empty-text="$t('list_empty')"
            :sort-desc.sync="isSortDirDesc"
            style="min-height: 60vh"
        >
          <template #cell(image)="data">
            <image-table-field :url="data.item.urlImage"></image-table-field>
          </template>
          <!-- Column: Name -->
          <template #cell(name)="data">
            <b-media vertical-align="center">
              <small class="text-muted">{{ data.item.name }}</small>
            </b-media>
          </template>

          <!-- Column: Description -->
          <template #cell(description)="data">
            <b-media vertical-align="center">
              <small class="text-muted">{{ data.item.description }}</small>
            </b-media>
          </template>

          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <b-dropdown
                variant="link"
                no-caret
                :right="$store.state.appConfig.isRTL"
            >
              <template #button-content>
                <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="align-middle text-body"
                />
              </template>
              <b-dropdown-item
                  :to="{ name: 'apps-banners-view', params: { id: data.item._id } }"
              >
                <feather-icon icon="FileTextIcon"/>
                <span class="align-middle ml-50">{{
                    $t("buttons.details")
                  }}</span>
              </b-dropdown-item>

              <div>
                <b-dropdown-item @click="showEditForm(data.item)">
                  <feather-icon icon="EditIcon"/>
                  <span class="align-middle ml-50">{{ $t("buttons.edit") }}</span>
                </b-dropdown-item>
              </div>

              <div>
                <b-dropdown-item @click="removeBanner(data.item._id)">
                  <feather-icon icon="TrashIcon"/>
                  <span class="align-middle ml-50">{{
                      $t("buttons.delete")
                    }}</span>
                </b-dropdown-item>
              </div>

              <div>
                <b-dropdown-item @click="selectBanner(data.item._id)">
                  <feather-icon icon="CheckSquareIcon"/>
                  <span class="align-middle ml-50">{{
                      $t("labels.select")
                    }}</span>
                </b-dropdown-item>
              </div>
            </b-dropdown>
          </template>

          <template #cell(selection)="data">
            <feather-icon v-if="data.item.selected" :icon="'CheckIcon'"/>
          </template>
        </b-table>
      </b-overlay>

      <div class="mx-2 mb-2">
        <b-row>
          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
            >{{ $t("labels.showing") }} {{ dataMeta.from }}
              {{ $t("labels.to") }} {{ dataMeta.to }} {{ $t("labels.of") }}
              {{ dataMeta.of }} {{ $t("labels.entries") }}</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
                v-model="currentPage"
                :total-rows="totalBanners"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18"/>
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18"/>
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import {ref, onUnmounted} from "@vue/composition-api";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
//import BannersListFilters from './BannersListFilters.vue'
import useBannersList from "./useBannersList";
import bannerStoreModule from "../bannerStoreModule";
import BannerListAddNew from "./BannerListAddNew.vue";
import ImageTableField from "@/views/components/ImageTableField.vue";
import BannersEdit from "../banners-edit/BannersEdit.vue";

export default {
  components: {
    //BannersListFilters,
    BannerListAddNew,
    ImageTableField,
    BannersEdit,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,

    vSelect,
  },
  data() {
    return {
      banner: {},
      showModal: false,
      pages: [
        {
          value: 1,
          text: "Home"
        },
        {
          value: 2,
          text: "About"
        }
      ]
    };
  },

  computed: {
    tableColumns() {
      return [
        {key: "image", sortable: true, label: this.$t("labels.image")},
        {key: "name", sortable: true, label: this.$t("labels.name")},
        {
          key: "description",
          sortable: true,
          label: this.$t("labels.description"),
        },
        {key: "type", sortable: true, label: this.$t("labels.type")},
        {key: "actions", label: this.$t("labels.actions")},
        {key: "selection", label: ''},
      ]
    }
  },

  setup() {
    const BANNER_APP_STORE_MODULE_NAME = "app-banner";

    if (!store.hasModule(BANNER_APP_STORE_MODULE_NAME))
      store.registerModule(BANNER_APP_STORE_MODULE_NAME, bannerStoreModule);
    onUnmounted(() => {
      if (store.hasModule(BANNER_APP_STORE_MODULE_NAME))
        store.unregisterModule(BANNER_APP_STORE_MODULE_NAME);
    });

    const isAddNewBanner = ref(false);

    const {
      fetchBanners,
      selectBanner,
      perPage,
      currentPage,
      totalBanners,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refBannerListTable,
      refetchData,
      recordDeleted,
      loading,
      // UI

      resolveBannerStatusVariant,
    } = useBannersList();

    return {
      // Sidebar
      isAddNewBanner,
      fetchBanners,
      selectBanner,
      perPage,
      currentPage,
      totalBanners,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refBannerListTable,
      refetchData,
      recordDeleted,
      loading,
      // UI
      resolveBannerStatusVariant,
    };
  },
  methods: {
    removeBanner(Id) {
      store.dispatch("app-banner/removeBanner", {id: Id}).then(() => {
        this.recordDeleted = !this.recordDeleted;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('banner_removed'),
            icon: "EditIcon",
            variant: "success",
          },
        });
      });
    },
    showEditForm(banner) {
      this.banner = banner;
      console.log(this.banner);
      this.showModal = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select";
</style>
