<template>
  <b-modal
      id="add-new-banner-sidebar"
      :visible="isAddNewBannerSidebarActive"
      :title="$t('tabs.addnewbanner')"
      bg-variant="white"
      sidebar-class="sidebar-lg"
      shadow
      backdrop
      no-header
      hide-footer
      right
      @hidden="resetForm"
      @change="(val) => $emit('update:is-add-new-banner-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- BODY -->
      <validation-observer ref="refFormObserver">
        <!-- Form -->
        <b-form
            class="p-2"
            @submit.prevent="onSubmit"
            @reset.prevent="resetForm"
        >
          <!-- Banner name -->
          <validation-provider
              #default="validationContext"
              name="Name"
              rules="required"
          >
            <b-form-group
                :label="$t('labels.firstname')"
                label-for="name"
            >
              <template #label>{{ $t('labels.name') }}</template>
              <b-form-input
                  id="name"
                  v-model="bannerData.name"
                  :state="getValidationState(validationContext)"
                  trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Description -->
          <validation-provider
              #default="validationContext"
              name="Description"
          >
            <b-form-group
                :label="$t('labels.description')"
                label-for="description"
            >
              <template #label>{{ $t('labels.description') }}</template>
              <b-form-input
                  id="description"
                  v-model="bannerData.description"
                  :state="getValidationState(validationContext)"
                  trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- URL Image -->
          <validation-provider
              #default="validationContext"
              name="UrlImage"
              rules="required|url"
          >
            <b-form-group
                :label="$t('labels.image')"
                label-for="imagen"
            >
              <template #label>{{ $t('labels.image') }}</template>
              <b-form-file
                  v-model="bannerData.image"
                  :state="Boolean(bannerData.image) || getValidationState(validationContext)"
                  :placeholder="$t('chose_drop_file')"
                  :drop-placeholder="$t('drop_file')"
              ></b-form-file>
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- type -->
          <validation-provider
              #default="validationContext"
              name="Type"
              rules="required"
          >
            <b-form-group
                :label="$t('labels.type')"
                label-for="type"
            >
              <template #label>{{ $t('labels.type') }}</template>
              <b-form-select
                  id="type"
                  :options="optionsType"
                  v-model="bannerData.type"
                  :state="getValidationState(validationContext)"
                  trim
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
              #default="validationContext"
              name="Page"
              rules="required"
          >
            <b-form-group
                :label="$t('page')"
                label-for="b-page"
            >
              <template #label>{{ $t('page') }}</template>

              <b-form-checkbox-group
                  id="b-page"
                  v-model="bannerData.page"
                  :state="getValidationState(validationContext)"
                  :options="pages"
                  trim>
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-checkbox-group>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-3">
            <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
            >
              {{ $t('buttons.add') }}
            </b-button>
            <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="button"
                variant="outline-secondary"
                @click="hide"
            >
              {{ $t('buttons.cancel') }}
            </b-button>
          </div>

        </b-form>
      </validation-observer>

    </template>
  </b-modal>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormFile, BFormInvalidFeedback, BButton, BFormSelect,
} from 'bootstrap-vue'
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import {ref} from '@vue/composition-api'
import {required, alphaNum} from '@core/utils/validations/validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
import countries from '@/@fake-db/data/other/countries'
import store from '@/store'
import axios from "@/libs/axios";

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormSelect,
    vSelect,
    BFormFile,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewBannerSidebarActive',
    event: 'update:is-add-new-banner-sidebar-active',
  },
  props: {
    isAddNewBannerSidebarActive: {
      type: Boolean,
      required: true,
    },

    pages: {
      default: []
    }

  },
  data() {
    return {
      required,
      alphaNum,
      countries,
      bannerExist: '',
      optionsType: [
        {value: 'header', text: this.$t('header')},
        {value: 'popup', text: 'Pop up'}
      ],
    }
  },
  setup(props, {emit}) {
    const blankBannerData = {
      name: '',
      description: '',
      image: null,
      type: '',
      whitelabels: []
    }

    const bannerData = ref(blankBannerData)
    const resetbannerData = () => {
      bannerData.value = blankBannerData
    }

    const uploadImage = () => {
      const formData = new FormData();
      formData.append('images', bannerData.value.image)
      formData.append("path", "banner");
      return axios.post(
          `setImages/${store.state.whitelabelCurrencyNabvar.whitelabel.client}`,
          formData
      )
    }

    const onSubmit = () => {
      uploadImage().then(response => {
        const form = new FormData()
        const data = bannerData.value
        console.log(data)
        form.append('name', data.name)
        form.append('type', data.type)
        form.append('description', data.description)
        form.append('urlImage', response.data.files[0])
        form.append('client', store.state.whitelabelCurrencyNabvar.whitelabel.client)
        store.dispatch('app-banner/addBanner', form)
            .then(() => {
              emit('refetch-data')
              emit('update:is-add-new-banner-sidebar-active', false)
            }).catch(() => {
          emit('update:is-add-new-banner-sidebar-active', false)
        })
      })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetbannerData)

    return {
      bannerData,
      onSubmit,
      refFormObserver,
      getValidationState,
      resetForm
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select';

#add-new-banner-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
